import { addSourceBufferWhenOpen, appendBufferWhenOpen, combineBuffers } from './lib/utils'

import {useState, useEffect, useCallback, Fragment, useRef} from 'react'
import {VideoScene, IframeScene, topScene, sceneMap, logSceneTransitionInAnalytics, filesVersionNumber} from './scenes'
import ClickableSvg from './ClickableSvg'

const standalone = document.body.classList.contains('standalone')

const topSceneKey = topScene.id

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const _startingSceneKey = urlParams.get('dtss') || window?.drupalSettings?.dream_terminal?.starting_scene || topSceneKey
const startingSceneKey = (sceneMap[_startingSceneKey] instanceof VideoScene) ? _startingSceneKey : topSceneKey

const defaultGcm = window?.drupalSettings?.dream_terminal?.gcm || (standalone ? 'standalone' : 'default')
const baseFileFolder = '/sites/default/files/dream-terminal/'
const availableGcms = window?.drupalSettings?.dream_terminal?.gcm_picker_options || false
const optimalTimeToCompleteLoopBeforeTransition = 500
const mediaSource = new MediaSource();
const _appendBufferWhenOpen = (sourceBuffer, data) => appendBufferWhenOpen(mediaSource, sourceBuffer, data)

const createBufferForUrl = async (url) => {
  const data = (await fetch(url))
  return data.arrayBuffer();
}

export default function App() {
  const [indexExpanded, setIndexExpanded] = useState(true)
  const toggleIndex = () => setIndexExpanded(current => !current)

  const [gcm, setGcm] = useState(defaultGcm)
  const [currentPassword, setCurrentPassword] = useState('')
  const [expanded, setExpanded] = useState(false)

  const collapse = (e) => {
    e.preventDefault()
    setExpanded(false)
    document.body.classList.remove('dream-terminal-expanded');
  }

  const [activeSceneKey, setActiveSceneKey] = useState(startingSceneKey)
  const [preloadedVideoUrls, setPreloadedVideoUrls] = useState({})
  const [preloadedIframeScenes, setPreloadedIframeScenes] = useState([])
  const [sourceBuffer, setSourceBuffer] = useState(false)
  const [nextSegment, setNextSegment] = useState(null)
  const [transitionDuration, setTransitionDuration] = useState(0)
  const [transitioning, setTransitioning] = useState(false)
  const [mounted, setMounted] = useState(false)

  const videoRef = useRef(null)

  const getFullVideoUrl = useCallback ((url) => `${baseFileFolder}${gcm}${url}`, [gcm])
  const getFullIframeUrl = useCallback ((url) => `${url}`)
  // @todo when/if we have the gcm starting frames in interactive products,
  // modify the above line to the following and it should work.
  // const getFullIframeUrl = useCallback ((url) => `${url}?gcm=${gcm}`, [gcm])

  const activeScene = sceneMap[activeSceneKey]

  const mount = async () => {

    videoRef.current.src = URL.createObjectURL(mediaSource);
    videoRef.current.loop = false

    const _sourceBuffer = await addSourceBufferWhenOpen(mediaSource, `video/webm; codecs="vp9"`, 'sequence');
    _appendBufferWhenOpen(_sourceBuffer, preloadedVideoUrls[activeScene.loopVideo])
    setSourceBuffer(_sourceBuffer)
    logSceneTransitionInAnalytics(undefined, startingSceneKey)
  }

  const handleProgress = useCallback((e) => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (duration - currentTime <= 1) {
      const _nextSegment = nextSegment || preloadedVideoUrls[activeScene.loopVideo] || preloadedVideoUrls[activeScene.parent.loopVideo]
      _appendBufferWhenOpen(sourceBuffer, _nextSegment)
      setNextSegment(null)
    }
  }, [sourceBuffer, preloadedVideoUrls, nextSegment, activeScene])

  useEffect(() => {
    const handleMountAndGcmChange = async () => {
      const buffer = await createBufferForUrl(getFullVideoUrl(activeScene.loopVideo))
      setPreloadedVideoUrls(current => ({[activeScene.loopVideo]: buffer}))

      if (mounted) {
        doPreloadsForScene(activeSceneKey)
      } else {
        videoRef.current.src = getFullVideoUrl(activeScene.loopVideo)
        videoRef.current.loop = true
        videoRef.current.paused && videoRef.current.play();
      }
    }

    handleMountAndGcmChange()
  },[mounted, gcm])

  useEffect(() => {
    if (!mounted) {
      return
    }

    !!sourceBuffer && sourceBuffer.addEventListener('updateend', handleProgress)
    !!videoRef.current && videoRef.current.addEventListener("timeupdate", handleProgress);

    return () => {
      !!sourceBuffer && sourceBuffer.removeEventListener('updateend', handleProgress)
      !!videoRef.current && videoRef.current.removeEventListener("timeupdate", handleProgress);
    }
  }, [mounted, sourceBuffer, preloadedVideoUrls, nextSegment, activeScene])

  const expand = async (e) => {
    e.preventDefault()

    if (standalone && (currentPassword != 'lokkikeitto')) {
      return
    }

    if (!mounted) {
      await mount()
      setMounted(true)
    }

    document.body.classList.add('dream-terminal-expanded');
    setExpanded(true)
  }

  const onPathClick = (id) => (e) => {
    e.preventDefault()
    transitionToScene(id)
  }

  const transitionToScene = (targetSceneKey) => {
    // Stop transition to current scene.
    if (targetSceneKey == activeSceneKey) {
      return
    }
    logSceneTransitionInAnalytics(activeSceneKey, targetSceneKey)
    setTransitioning(true)
    // Get target scene data
    const targetScene = sceneMap[targetSceneKey]

    // if target or active is not video, we have no transitions, just set the
    // new active scene
    if (!(targetScene instanceof VideoScene) || !(activeScene instanceof VideoScene)) {
      setActiveSceneKey(targetSceneKey)
      doPreloadsForScene(targetSceneKey)
      setTransitionDuration(0)
      setTransitioning(false)
      return
    }

    // check the transitionable
    let _nextSegment
    let _transitionDuration
    let _tmpKey
    if (activeScene?.parent?.id == targetScene.id) {
      // play active leaving
      // play target loop
      // @todo can result in error if preload failed
      _nextSegment = combineBuffers(
        preloadedVideoUrls[activeScene.leavingVideo], // @todo handle preload not finished in al cases
        preloadedVideoUrls[targetScene.loopVideo],
      )
      _transitionDuration = activeScene.leavingVideoDuration
    } else if (targetScene?.parent?.id == activeScene.id) {
      // play target entering
      // play target loop
      // @todo can result in error if preload failed -> mitigated by not showing
      // target svg path until video is loaded
      _nextSegment = combineBuffers(
        preloadedVideoUrls[targetScene.enteringVideo], // @todo handle preload not finished in al cases
        preloadedVideoUrls[targetScene.loopVideo],
      )
      _transitionDuration = targetScene.enteringVideoDuration
    } else if (targetScene.id == topSceneKey) {
      // play chain of returns
      // play target loop
      // @todo can result in error if preload failed
      _nextSegment = preloadedVideoUrls[activeScene.leavingVideo]
        _transitionDuration = activeScene.leavingVideoDuration
      _tmpKey = activeScene.parent.id
      do {
        _nextSegment = combineBuffers(
          _nextSegment,
          preloadedVideoUrls[sceneMap[_tmpKey].leavingVideo],
        )
        _transitionDuration += sceneMap[_tmpKey].leavingVideoDuration
        _tmpKey = sceneMap[_tmpKey].parent.id
      } while (sceneMap[_tmpKey].leavingVideo);
    } else {
      // we have no way to smoothly transition if a wierd transition is
      // requested, just set the new active scene
      setActiveSceneKey(targetSceneKey)
      doPreloadsForScene(targetSceneKey)
      setTransitionDuration(0)
      setTransitioning(false)
      return
    }

    setNextSegment(_nextSegment)
    setActiveSceneKey(targetSceneKey)
    setTransitionDuration(_transitionDuration)
    videoRef.current.paused && videoRef.current.play();
    doPreloadsForScene(targetSceneKey)
  }

  const preloadVideo = async (url) => {
    // @todo we would need this "if" for performance, but then changing gcm does
    // not work correctly, because preloadedVideoUrls is stale. Investigate.
    // if (!Object.keys(preloadedVideoUrls).includes(url)) {
      const buffer = await createBufferForUrl(getFullVideoUrl(url))
      setPreloadedVideoUrls(current => Object.keys(current).includes(url) ? current : {...current, [url]: buffer})
    // }
  }

  const doPreloadsForScene = (sceneKey) => {
    const scene = sceneMap[sceneKey]

    if (scene instanceof VideoScene && scene.leavingVideo) {
      preloadBackToTop(scene)
      preloadVideo(scene.parent.loopVideo)
    }

    scene.inner.forEach(innerScene => {
      if (innerScene instanceof IframeScene) {
        setPreloadedIframeScenes(current => current.includes(innerScene) ? current : [...current, innerScene])
      } else if (innerScene instanceof VideoScene) {
        preloadVideo(innerScene.enteringVideo)
        preloadVideo(innerScene.loopVideo)
      }
    })
  }

  const preloadBackToTop = (scene) => {
    if (scene.leavingVideo) {
      preloadVideo(scene.leavingVideo)
      preloadBackToTop(scene.parent)
    } else {
      preloadVideo(scene.loopVideo)
    }
  }

  const onTransitionClick = (sceneKey) => (e) => {
    e.preventDefault()
    transitionToScene(sceneKey)
  }

  const backToTop = (e) => {
    e.preventDefault()
    // @todo queue a series of transitions
    transitionToScene(topSceneKey)
  }

  useEffect(() => {
    setTransitioning(!!transitionDuration)
    if (!transitionDuration) {
      return
    }

    const ct = videoRef.current.currentTime
    const cd = videoRef.current.duration

    const time = (cd - ct) * 1000

    const speed = Math.max(Math.min(time ? time / optimalTimeToCompleteLoopBeforeTransition : 1, 3), 1)
    videoRef.current.playbackRate = speed

    const resetSpeedAfter = time / speed
    const timer = setTimeout(() => {
      videoRef.current.playbackRate = 1
    }, resetSpeedAfter);

    const timer2 = setTimeout(() => {
      setTransitionDuration(0)
    }, resetSpeedAfter + transitionDuration + 300);

    return () => {
      clearTimeout(timer)
      clearTimeout(timer2)
    }
  }, [transitionDuration])

  const togglePlaying = (e) => {
    e.preventDefault()
    e.target.classList.toggle('play-button')
    e.target.classList.toggle('pause-button')
    videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause();
  }

  return (
    <Fragment>
      <div className={["dream-terminal-inner", !expanded ? 'collapsed' : undefined, (activeScene instanceof IframeScene) ? 'has-active-iframe' : undefined].join(' ')}>
        {!transitioning && <ClickableSvg indexExpanded={indexExpanded} preloadedVideos={Object.keys(preloadedVideoUrls)} className="clickable-svg" activeScene={activeScene} onPathClick={onPathClick} />}
        {preloadedIframeScenes.map(scene => (<iframe className={activeSceneKey == scene.id ? 'active' : 'inactive'} key={scene.id} src={`${scene.src}`} />))}
        <video onClick={onTransitionClick(activeScene.parent?.id || 0)} ref={videoRef} muted src=""></video>
        {!transitioning && <div className="buttons-container">
          {expanded && <a href="#" className="collapse-button" onClick={collapse}></a>}
          {(activeSceneKey == topSceneKey) && !!availableGcms && <div className="gcm-selection-wrapper"><select onChange={(e) => setGcm(e.target.value)}>
            <option value="default">Konecranes</option>
            {availableGcms.map((data, key) => (
              <option value={data.value} key={data.label}>{data.label}</option>
            ))}
          </select></div>}
          <div className="top-left-buttons-container">
            {standalone && expanded && <a href="#" className="refresh-button" onClick={(e) => {e.preventDefault(); window.location.reload()}}></a>}
            {expanded && (activeScene instanceof VideoScene) && <a href="#" className="scene-index-toggle-button" onClick={toggleIndex}></a>}
            {expanded && activeScene.hasPauseButton && <a href="#" className={videoRef.current.paused ? "play-button" : "pause-button"} onClick={togglePlaying}></a>}
            {activeScene.hasBackToTopButton && <a href="#" className="top-button" onClick={backToTop}></a>}
            {activeScene.hasBackButton && <a href="#" className="back-button" onClick={onTransitionClick(activeScene.parent.id)}></a>}
          </div >
        </div >}
        {!expanded && standalone && (
          <form className="login-form" onSubmit={expand}>
            <label htmlFor="dream-terminal-password">Log in</label>
            <input name="dream-terminal-password" type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
            <button type="submit" className="expand-button">Launch Dream Terminal</button>
          </form>
        )}
        {!expanded && !standalone && <div class="cta-expand-button cta with-hover red"><a href="#" onClick={expand}>Explore your dream terminal</a></div>}
        {standalone && <div class="version-number">Konecranes Dream Terminal v{filesVersionNumber}</div>}
        <div className="debug-information">
          <p><strong>GCM:</strong> {gcm}</p>
          <p><strong>Active scene:</strong> {activeSceneKey}</p>
          <p><strong>Preloaded Videos</strong></p>
          {Object.keys(preloadedVideoUrls).map(url => (<p key={url}>{url}</p>))}
          {!Object.keys(preloadedVideoUrls).length && <p>-</p>}
          <p><strong>Preloaded Frames</strong></p>
          {preloadedIframeScenes.map(scene => (<p key={scene.id}>({scene.id}) {scene.src}</p>))}
          {!preloadedIframeScenes.length && <p>-</p>}
        </div>
      </div>
    </Fragment>
  );
}
