// Increase this for cache-busting when files change
const filesVersionNumber = '1.08'

class SceneBase {
  constructor({id, inner, friendlyName}) {
    this.id = id;
    this.inner = inner || []
    this.friendlyName = friendlyName || id
  }

}

class ExternalLink {
  constructor(props) {
    this.id = props.url;
    this.inner = [];
    this.url = props.url;
    this.buttonText = props.buttonText;
    this.sceneType = 'externalLink';
    this.trace = props.trace;
  }
}

class VideoScene extends SceneBase {
  constructor(props) {
    super(props);
    this.withSvg = props.svg || false
    this.enteringVideo = '/' + props.id + `/entering.webm?v=${filesVersionNumber}`;
    this.leavingVideo = '/' + props.id + `/leaving.webm?v=${filesVersionNumber}`;
    this.leavingVideoDuration = props.leavingVideoDuration;
    this.enteringVideoDuration = props.enteringVideoDuration;
    this.loopVideo = '/' + props.id + `/loop.webm?v=${filesVersionNumber}`;
    this.trace = props.trace;
  }
}

class TopScene extends VideoScene {
  constructor(props) {
    super(props);
    this.enteringVideo = false;
    this.leavingVideo = false;
    this.leavingVideoDuration = 0;
    this.enteringVideoDuration = 0;
    this.hasSvg = true;
    this.hasBackButton = false;
    this.hasBackToTopButton = false;
    this.sceneType = 'top'
  }
}

class ModuleScene extends VideoScene {
  constructor(props) {
    super(props);
    this.hasSvg = true;
    this.hasBackButton = true;
    this.hasBackToTopButton = false;
    this.enteringVideoDuration = props.enteringVideoDuration || 3033;
    this.leavingVideoDuration = props.leavingVideoDuration || 833;
    this.sceneType = 'module'
  }
}

class ProductScene extends VideoScene {
  constructor(props) {
    super(props);
    this.hasSvg = false;
    this.hasBackButton = true;
    this.hasBackToTopButton = props.hasBackToTopButton ?? true;
    this.enteringVideoDuration = props.enteringVideoDuration || 1933;
    this.leavingVideoDuration = props.leavingVideoDuration || 833;
    this.sceneType = 'product'
    this.hasPauseButton = true
  }
}

class IframeScene extends SceneBase {
  constructor(props) {
    const standalone = typeof document !== 'undefined' ? document.body.classList.contains('standalone') : true

    super(props);
    this.src = props.src + `/index.html?v=${filesVersionNumber}`;
    if (standalone) {
      this.src = this.src.toLowerCase()
    }
    this.hasSvg = false;
    this.hasBackButton = true;
    this.hasBackToTopButton = false;
    this.buttonText = props.buttonText;
    this.sceneType = 'iframe'
    this.trace = props.trace;
  }
}

const topScene = new TopScene({
  id: 'Top',
  friendlyName: 'Dream Terminal',
  inner: [
    new ModuleScene({
      id: 'ContainerYardManual',
      friendlyName: 'Manual Container Yard',
      trace: "M1020,402.8l8.6,187.1c0.3,6.9,6,12.2,12.9,12.2l158.3-2.1 c7.8-0.1,13.6-7.1,12.4-14.7l-29.4-185c-1-6.2-6.3-10.7-12.6-10.7h-137.5C1025.5,389.4,1019.7,395.5,1020,402.8z",
      inner: [
        new ProductScene({
          id: 'ContYardManu_RTG',
          friendlyName: 'RTG',
          trace: "M721.4,384.9L595.7,485.6c-5.7,4.6-8.5,11.8-7.3,19l26.7,167.2 c1.1,6.7,5.4,12.3,11.5,15.2l129.1,60c8.4,3.9,18.5,1.6,24.3-5.6l123.4-152.4c3.1-3.8,4.7-8.6,4.5-13.5L903.2,450 c-0.3-8.4-5.8-15.8-13.8-18.5l-148.9-50C734,379.3,726.8,380.6,721.4,384.9z",
          inner: [
            new IframeScene({id: 'ContYardManu_RTG_tech', src: '/sites/default/files/interactive-products/RTG', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'ContYardManu_RTG_service', src: '/sites/default/files/interactive-products/RTGService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=RTG&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'ContYardManu_Straddle',
          friendlyName: 'Straddle carrier',
          trace: "M1130.9,395.2l-9.2,112.9c-0.3,4.2,2.1,8.1,6.1,9.7l63.9,24.5 c3.9,1.5,8.3,0.3,10.9-3l24.5-31.5c1.1-1.4,1.8-3.1,2-4.9l9.4-97.7c0.4-4.4-2.2-8.5-6.4-9.9l-68.7-23.7c-3-1-6.4-0.5-9,1.5 l-19.9,15.5C1132.4,390.1,1131.1,392.5,1130.9,395.2z",
          inner: [
            new IframeScene({id: 'ContYardManu_Straddle_tech', src: '/sites/default/files/interactive-products/Straddle', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'ContYardManu_Straddle_service', src: '/sites/default/files/interactive-products/StraddleService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=Straddle%20carrier&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'ContYardManu_Sprinter',
          friendlyName: 'Sprinter carrier',
          trace: "M1605.4,530.7l-33.2,88.8c-2.5,6.7,1,14.1,7.8,16.4l123.8,41.7 c6.7,2.3,13.9-1.4,16.1-8.1l30.1-94.1c2.1-6.7-1.6-13.9-8.4-15.9L1620.9,523C1614.5,521.1,1607.8,524.4,1605.4,530.7z",
          inner: [
            new IframeScene({id: 'ContYardManu_Sprinter_tech', src: '/sites/default/files/interactive-products/Sprinter', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'ContYardManu_Sprinter_service', src: '/sites/default/files/interactive-products/SprinterService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=Sprinter%20carrier&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
      ],
    }),
    new ModuleScene({
      id: 'ContainerYardAutomatic',
      friendlyName: 'Automated Container Yard',
      trace: "M1176.9,406.2l31.4,178.7c1.2,6.8,7.1,11.8,14.1,11.8h187.9 c9.8,0,16.7-9.6,13.6-18.9l-60.3-178.7c-2-5.8-7.4-9.7-13.6-9.7H1191C1182.1,389.4,1175.4,397.5,1176.9,406.2z",
      inner: [
        new ProductScene({
          id: 'ContYardAuto_AGV',
          friendlyName: 'AGV',
          trace: "M1479.8,492.3l-16.1,36.5c-2,4.5,0.5,9.6,5.2,10.9l138.4,37 c4.1,1.1,8.3-1.2,9.6-5.2l12.5-38.8c1.4-4.4-1.2-9-5.6-10.1l-134.8-34.7C1485.3,486.8,1481.4,488.7,1479.8,492.3z",
          inner: [
            new IframeScene({id: 'ContYardAuto_AGV_tech', src: '/sites/default/files/interactive-products/AGV', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'ContYardAuto_AGV_service', src: '/sites/default/files/interactive-products/AGVService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=AGV&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'ContYardAuto_LiftAGV',
          friendlyName: 'Lift AGV',
          trace: "M1475.1,540.6l-12.8,30.6c-2.4,5.7,0.7,12.2,6.7,13.9l138.4,40 c5.5,1.6,11.2-1.5,12.8-7l10.1-33.8c1.7-5.6-1.6-11.4-7.2-13l-135.8-36.8C1482.3,533.2,1477.1,535.8,1475.1,540.6z",
          inner: [
            new IframeScene({id: 'ContYardAuto_LiftAGV_tech', src: '/sites/default/files/interactive-products/LiftAGV', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'ContYardAuto_LiftAGV_service', src: '/sites/default/files/interactive-products/LiftAGVService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=Lift%20AGV&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'ContYardAuto_ARMG',
          friendlyName: 'ASC',
          trace: "M581.1,119.3l-197.1,110c-6.3,3.5-9.6,10.8-8.1,17.9l11.8,56.4 c1.2,6,5.7,10.8,11.5,12.5l141,41.6c5.2,1.5,10.8,0.4,15-2.9l169.9-134.6c4.1-3.2,6.4-8.1,6.3-13.3l-0.9-46.2 c-0.1-7.7-5.5-14.2-13-15.9l-124.7-27.1C588.8,116.7,584.7,117.3,581.1,119.3z",
          inner: [
            new IframeScene({id: 'ContYardAuto_ARMG_tech', src: '/sites/default/files/interactive-products/ARMG', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'ContYardAuto_ARMG_service', src: '/sites/default/files/interactive-products/ARMGService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=ARMG&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'ContYardAuto_ARTG',
          friendlyName: 'ARTG',
          trace: "M1069.3,468.6L980,585.3c-2.3,3-3.5,6.7-3.4,10.4l2.4,80.5 c0.2,6.7,4.5,12.7,10.9,14.9l138.3,49.4c7.6,2.7,16-0.5,19.9-7.5l80.2-146.1c1.2-2.2,1.9-4.7,2-7.2l2.6-65.6 c0.3-7.7-4.8-14.6-12.2-16.5l-134.3-35C1080,461,1073.3,463.4,1069.3,468.6z",
          inner: [
            new IframeScene({id: 'ContYardAuto_ARTG_tech', src: '/sites/default/files/interactive-products/ARTG', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'ContYardAuto_ARTG_service', src: '/sites/default/files/interactive-products/ARTGService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=ARTG&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        // new ProductScene({
        //   id: 'ContYardAuto_ARTGStreetB',
        //   friendlyName: 'ARTG - Street bogie',
        //   trace: "M424.6,570.8l32.1,133.7c1.2,5.1,4.8,9.4,9.7,11.4l73.6,31.3 c6.4,2.7,13.9,1.2,18.6-3.9l134.1-142.6c3.4-3.6,5-8.5,4.4-13.4l-13.6-111.3c-0.8-6.5-5.4-12-11.7-13.9l-78.4-23.9 c-5.1-1.6-10.7-0.6-15,2.7L430.7,553.7C425.4,557.7,423,564.4,424.6,570.8z",
        //   inner: [
        //     new IframeScene({id: 'ContYardAuto_ARTG_tech', src: '/sites/default/files/interactive-products/ARTG', buttonText: 'Explore the equipment'}),
        //     new IframeScene({id: 'ContYardAuto_ARTG_service', src: '/sites/default/files/interactive-products/RTGService', buttonText: 'Explore the related services'}),
        //     new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=Street%20bogie&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
        //   ],
        // }),
        new IframeScene({
          id: 'ContYardAuto_ROS',
          src: '/sites/default/files/interactive-products/ROS',
          buttonText: 'Remote Operating Station',
        }),
      ],
    }),
    new ModuleScene({
      id: 'Intermodal',
      friendlyName: 'Intermodal Cranes',
      trace: "M946.9,286.8V376c0,7.4,6,13.4,13.4,13.4l400.9-1c9.2,0,15.6-9.1,12.6-17.8l-30.8-88.3 c-1.9-5.4-6.9-9-12.6-9H960.2C952.8,273.4,946.9,279.4,946.9,286.8z",
      inner: [
        new ProductScene({
          id: 'Intermodal_RMG_Cantilever_IntermodalSpreader',
          friendlyName: 'RMG',
          trace: "M850.4,500l254.1,242.3 c3.6,3.5,9.1,4.3,13.5,2l184.3-93.6c7.7-3.9,8.8-14.6,1.9-19.9l-270.4-211.6c-3.2-2.5-7.6-3.2-11.4-1.8l-168,62.8 C846.2,483.4,844,493.9,850.4,500z",
          inner: [
            new IframeScene({id: 'Intermodal_RMG_tech', src: '/sites/default/files/interactive-products/RMG', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'Intermodal_RMG_service', src: '/sites/default/files/interactive-products/RMGService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=RMG&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'Intermodal_RTG',
          friendlyName: 'RTG',
          trace: "M381.6,439l67.6,130.6c2.6,5,8.5,7.4,13.8,5.6L603.2,528c7-2.4,10-10.6,6.1-16.9 l-76.4-122.4c-2.7-4.4-8-6.4-13-4.9l-131.4,38.9C381.6,424.8,378.3,432.6,381.6,439z",
          inner: [
            new IframeScene({id: 'Intermodal_RTG_tech', src: '/sites/default/files/interactive-products/RTG', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'Intermodal_RTG_service', src: '/sites/default/files/interactive-products/RTGService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=RTG&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'Intermodal_ReachStacker_IntermodalSpreader',
          friendlyName: 'Reach Stacker',
          trace: "M793.4,571.7l32.9,51.2c2.8,4.3,8.3,6.1,13.1,4.2 l71.4-28.8c6.6-2.7,8.8-10.9,4.5-16.5l-37-48.5c-2.9-3.8-8-5.2-12.5-3.5l-67.4,26.2C792.2,558.3,789.7,565.9,793.4,571.7z",
          inner: [
            new IframeScene({id: 'Intermodal_ReachStacker_tech', src: '/sites/default/files/interactive-products/ReachStacker', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'Intermodal_ReachStacker_service', src: '/sites/default/files/interactive-products/ReachStackerService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=Reach%20Stacker&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
      ],
    }),
    new ModuleScene({
      id: 'LiftTrucks',
      friendlyName: 'Lift Trucks',
      trace: "M867,402.4L859.8,582c-0.3,6.5,5,12,11.5,12h151.1c6.6,0,11.8-5.5,11.5-12.1l-8.8-178.7 c-0.3-6.1-5.3-10.9-11.4-10.9l-135.1-0.9C872.4,391.4,867.3,396.2,867,402.4z",
      inner: [
        new ProductScene({
          id: 'LiftTrucks_EmptyContHandler',
          friendlyName: 'Empty Container Handler',
          trace: "M903.9,808.4L832.6,859c-7,5-7.6,15.1-1.3,20.9l70.4,64.5 c4.7,4.3,11.8,4.7,17,1l73.2-52.9c7-5.1,7.5-15.4,0.9-21.1l-72.3-62.2C915.8,805.1,909,804.8,903.9,808.4z",
          inner: [
            new IframeScene({id: 'LiftTrucks_EmptyContHandler_tech', src: '/sites/default/files/interactive-products/EmptyContainerHandler', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'LiftTrucks_EmptyContHandler_service', src: '/sites/default/files/interactive-products/EmptyContainerHandlerService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=Empty%20Container%20Handler&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'LiftTrucks_ForkliftEVER',
          friendlyName: 'Forklift - E-VER',
          trace: "M1222.1,788.7l35.7-31c3.3-2.9,8.2-3.2,11.9-0.9l63.8,40.9 c5.3,3.4,6.2,10.7,1.8,15.3l-33.3,34.7c-3.3,3.5-8.7,4.1-12.7,1.4l-66.3-44.6C1217.7,800.8,1217.2,793,1222.1,788.7z",
          inner: [
            new IframeScene({id: 'LiftTrucks_EVER_tech', src: '/sites/default/files/interactive-products/ForkliftEVER', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'LiftTrucks_EVER_service', src: '/sites/default/files/interactive-products/ForkliftEVERService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=E-VER%20Forklift&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'LiftTrucks_LadenContHandler',
          friendlyName: 'Laden Container Handler',
          trace: "M553,1032l126.8-61.8c6-2.9,8.1-10.5,4.4-16.1l-50.4-76.6 c-3-4.6-8.9-6.3-13.9-4l-119,54.4c-5.7,2.6-8.1,9.5-5.3,15.1l42.6,84C541,1032.4,547.5,1034.6,553,1032z",
          inner: [
            new IframeScene({id: 'LiftTrucks_LadenContHandler_tech', src: '/sites/default/files/interactive-products/LadenContainerHandler', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'LiftTrucks_LadenContHandler_service', src: '/sites/default/files/interactive-products/LadenContainerHandlerService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=Laden%20Container%20Handler&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'LiftTrucks_ReachStacker',
          friendlyName: 'Reach Stacker',
          trace: "M790.4,721.2l94.3,76.1c3.9,3.1,9.4,3.2,13.3,0.1l77.8-60.2 c5.5-4.3,5.5-12.6,0.1-16.9l-92.1-72.7c-3.7-2.9-9-3.1-12.8-0.3l-80,56.8C785.2,708.3,784.9,716.8,790.4,721.2z",
          inner: [
            new IframeScene({id: 'LiftTrucks_ReachStacker_tech', src: '/sites/default/files/interactive-products/ReachStacker', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'LiftTrucks_ReachStacker_service', src: '/sites/default/files/interactive-products/ReachStackerService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=Reach%20Stacker&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
      ],
    }),
    new ModuleScene({
      id: 'MHC',
      friendlyName: 'Mobile Harbor Cranes',
      trace: "M582.6,382.1H887c12.1,0,21.8,10,21.3,22.1l-17.9,463.5c-0.4,11.4-9.8,20.5-21.3,20.5H352.8 c-13.8,0-24-13-20.7-26.4l32-130.3c2.3-9.5,10.8-16.1,20.5-16.2l65.6-0.5c9.3-0.1,17.5-6.2,20.2-15.1l91.9-302.4 C565,388.3,573.2,382.1,582.6,382.1z",
      inner: [
        new ProductScene({
          id: 'MHC_GEN6Barge',
          friendlyName: 'Open sea cargo handling',
          trace: "M56.4,740l397.3-185.7c6.8-3.2,14.8-2.5,21,1.8l305.1,212.7 c12.6,8.8,11.9,27.6-1.3,35.4l-434.8,258.1c-8.5,5-19.4,3.5-26.1-3.7L49.9,773.5C40.1,763.1,43.4,746.1,56.4,740z",
          inner: [
            new IframeScene({id: 'MHC_GEN6Barge_tech', src: '/sites/default/files/interactive-products/MHC', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'MHC_GEN6Barge_service', src: '/sites/default/files/interactive-products/MHCService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=MHC&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'MHC_GEN6Portal',
          friendlyName: 'Bulk handling',
          trace: "M610.1,473.6l340.4-169.8c2.6-1.3,5.7-1.4,8.4-0.3l231.9,95.8 c7.6,3.2,8.5,13.6,1.6,18L856,632.3c-3.3,2.1-7.4,2.1-10.7,0.2L609.4,491.5C602.5,487.4,602.9,477.2,610.1,473.6z",
          inner: [
            new IframeScene({id: 'MHC_GEN6Portal_tech', src: '/sites/default/files/interactive-products/MHC', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'MHC_GEN6Portal_service', src: '/sites/default/files/interactive-products/MHCService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=MHC&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'MHC_ProjectCargo',
          friendlyName: 'Project cargo handling',
          trace: "M1329.6,241.8l131.6,39.1c10,3,12.3,16.1,3.9,22.2l-217.7,160.6 c-3.8,2.8-8.9,3.3-13.1,1.1l-140.5-69.8c-9-4.5-9.4-17.2-0.7-22.2L1319.7,243C1322.7,241.2,1326.3,240.8,1329.6,241.8z",
          inner: [
            new IframeScene({id: 'MHC_ProjectCargo_tech', src: '/sites/default/files/interactive-products/MHC', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'MHC_ProjectCargo_service', src: '/sites/default/files/interactive-products/MHCService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=MHC&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'MHC_GeneralCargo',
          friendlyName: 'General cargo handling',
          trace: "M1565.1,601.2l190.1-197.8c5.6-5.8,3.2-15.4-4.4-18l-232.5-77.2 c-3.5-1.2-7.4-0.5-10.2,1.8l-208,165.1c-6.5,5.1-5.1,15.3,2.4,18.6l250.4,109.8C1557,605.5,1561.9,604.5,1565.1,601.2z",
          inner: [
            new IframeScene({id: 'MHC_GeneralCargo_tech', src: '/sites/default/files/interactive-products/MHC', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'MHC_GeneralCargo_service', src: '/sites/default/files/interactive-products/MHCService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=MHC&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'MHC_GEN6RubberTires',
          friendlyName: 'Container handling',
          trace: "M1364.4,492.6l193.9,87.2c9.3,4.2,11.7,16.3,4.6,23.7l-286.4,299 c-4.9,5.1-12.7,6-18.6,2.2l-210.9-134.5c-8.4-5.4-9.2-17.4-1.5-23.8l303.5-251.7C1353.2,491.1,1359.2,490.3,1364.4,492.6z",
          inner: [
            new IframeScene({id: 'MHC_GEN6RubberTires_tech', src: '/sites/default/files/interactive-products/MHC', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'MHC_GEN6RubberTires_service', src: '/sites/default/files/interactive-products/MHCService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=MHC&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
      ],
    }),
    new ModuleScene({
      id: 'STS',
      friendlyName: 'Ship-to-shore Cranes',
      trace: "M859.3,582.8l-11.5,163.2c-0.9,12.3,8.9,22.7,21.2,22.7h888.8c15.4,0,25.7-15.9,19.4-29.9l-72-160.6 c-3.4-7.6-11-12.5-19.3-12.5l-805.3-2.6C869.3,563.1,860,571.7,859.3,582.8z",
      inner: [
        new ProductScene({
          id: 'STS_doublebox',
          friendlyName: 'STS - Doublebox',
          trace: "M340.3,610.1l74.8,198.9c3.9,10.5,15.4,16,26.1,12.6l833.8-271 c8.5-2.8,14.3-10.7,14.5-19.6l2.6-179.5c0.1-7.2-3.5-14-9.6-17.9l-123.3-79.1c-4.7-3-10.4-4-15.8-2.8L332.2,431 c-10.8,2.4-17.9,12.7-16.2,23.6l23.2,151.3C339.5,607.4,339.8,608.8,340.3,610.1z",
          inner: [
            new IframeScene({id: 'STS_doublebox_tech', src: '/sites/default/files/interactive-products/STSDoublebox', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'STS_doublebox_service', src: '/sites/default/files/interactive-products/STSDoubleboxService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=STS&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'STS_monobox',
          friendlyName: 'STS - Monobox',
          trace: "M1265.2,410.7l181,133.9c3.1,2.3,7.2,3,10.9,1.7l439.4-147c4.7-1.6,8-5.9,8.2-10.9 l6.3-165.1c0.2-4.8-2.5-9.2-6.8-11.3l-152.1-72.4c-2.4-1.1-5.1-1.5-7.7-0.9l-474.5,102.5c-5.5,1.2-9.5,6.1-9.5,11.7V401 C1260.4,404.8,1262.2,408.4,1265.2,410.7z",
          inner: [
            new IframeScene({id: 'STS_monobox_tech', src: '/sites/default/files/interactive-products/STSMonobox', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'STS_monobox_service', src: '/sites/default/files/interactive-products/STSMonoboxService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=STS&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
      ],
    }),
    new ModuleScene({
      id: 'ServiceDepot',
      friendlyName: 'Service Depot',
      trace: "M788.9,233.5l-7.7,76.7c-0.8,7.7,5.3,14.3,13,14.3h123.7c7.2,0,13-5.8,13-13v-76.7 c0-7.2-5.8-13-13-13h-116C795.2,221.7,789.5,226.8,788.9,233.5z",
      inner: [
        new ProductScene({
          id: 'ServiceDepotCSeries',
          friendlyName: 'Chain Hoist Crane',
          trace: "M1069.1,645l136.5,24.3 c7.9,1.4,13.5,8.4,13.3,16.3l-4.4,150.4c-0.3,10.2-9.8,17.5-19.7,15.2l-135.5-31.1c-7.5-1.7-12.7-8.4-12.5-16.1l3.5-143.7 C1050.4,650.6,1059.4,643.3,1069.1,645z",
          inner: [
            new IframeScene({id: 'ServiceDepot_Cseries', src: '/sites/default/files/interactive-products/Cseries', buttonText: 'Explore the equipment'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=C-Series&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'ServiceDepotSSeries',
          friendlyName: 'Rope Hoist Crane',
          trace: "M1407,47.6l-254.7,54.8 c-6.2,1.3-10.6,6.8-10.5,13.1l0.5,96.6c0,6.1,4.2,11.4,10.1,12.9l132.1,32.9c2.7,0.7,5.6,0.5,8.2-0.6l253.8-101.3 c5-2,8.3-6.9,8.4-12.3l0.5-75.6c0-7.1-5.5-13-12.6-13.4l-132.2-7.4C1409.4,47.2,1408.2,47.3,1407,47.6z",
          inner: [
            new IframeScene({id: 'ServiceDepot_Sseries', src: '/sites/default/files/interactive-products/Sseries', buttonText: 'Explore the equipment'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=S-series&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'ServiceDepotAgilon',
          friendlyName: 'Agilon',
          trace: "M0,710.1l693.4-225.9 c5.8-1.9,12.2-1.7,17.9,0.5l98.8,37.9c10.3,4,17.1,13.8,17.2,24.9l1.4,183.4c0.1,10.2-5.7,19.6-14.8,24.2L165.3,1080H0L0,710.1z",
          inner: [
            new IframeScene({id: 'ServiceDepot_Agilon', src: '/sites/default/files/interactive-products/Agilon', buttonText: 'Explore the equipment'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=Agilon&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
      ],
    }),
    new ModuleScene({
      id: 'Shipyard',
      friendlyName: 'Shipyard',
      trace: "M595.8,293.9L477.8,675.4c-2,6.6-8.2,11.1-15.1,11.1H43c-12.3,0-19.9-13.5-13.5-24l136.1-221.9 c2.8-4.5,7.6-7.4,13-7.5l55.6-1.7c5.5-0.2,10.6-3.2,13.3-8.1l79.4-141.8c2.8-5,8.1-8.1,13.8-8.1h240.1 C591.4,273.4,599,283.7,595.8,293.9z",
      inner: [
        new ProductScene({
          id: 'ShipyardGoliath',
          friendlyName: 'Goliath Gantry crane',
          trace: "M441.3,214.6L210.1,534.2c-2.7,3.8-4.3,8.3-4.6,12.9l-20.9,351.8 c-0.8,13.9,10.1,25.8,24.1,26l429.5,7.5c11.7,0.2,21.9-7.9,24.4-19.3l89.6-408.9c1-4.7,0.7-9.6-1.1-14L647.7,220.2 c-3.6-9.5-12.8-15.8-22.9-15.8H461.2C453.3,204.4,445.9,208.2,441.3,214.6z",
          inner: [
            new IframeScene({id: 'Shipyard_Goliath_tech', src: '/sites/default/files/interactive-products/Goliath', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'Shipyard_Goliath_service', src: '/sites/default/files/interactive-products/GoliathService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=Goliath&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'ShipyardSBS',
          friendlyName: 'Single boom shipyard crane',
          trace: "M928.2,461.8v174.8c0,8.6,7,15.6,15.6,15.6h206.6c8.6,0,15.6-7,15.6-15.6V461.8 c0-8.6-7-15.6-15.6-15.6H943.8C935.2,446.2,928.2,453.2,928.2,461.8z",
          inner: [
            new IframeScene({id: 'Shipyard_SBS', src: '/sites/default/files/interactive-products/SBS', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'Shipyard_SBS_Service', src: '/sites/default/files/interactive-products/SBSService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=Single%20boom%20shipyard&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'ShipyardDBS',
          friendlyName: 'Double boom shipyard crane',
          trace: "M714.8,541.7v313.5c0,9.3,7.6,16.9,16.9,16.9h238.1c9.3,0,16.9-7.6,16.9-16.9V684.4 c0-3.7-1.2-7.2-3.4-10.1L876,531.5c-3.2-4.2-8.2-6.7-13.5-6.7H731.7C722.4,524.8,714.8,532.3,714.8,541.7z",
          inner: [
            new IframeScene({id: 'Shipyard_DBS', src: '/sites/default/files/interactive-products/DBS', buttonText: 'Explore the equipment'}),
            new IframeScene({id: 'Shipyard_DBS_Service', src: '/sites/default/files/interactive-products/DBSService', buttonText: 'Explore the related services'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=Double%20boom%20shipyard&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
        new ProductScene({
          id: 'ShipyardOHC',
          friendlyName: 'Overhead crane',
          trace: "M654.1,311.8l-36.5,126.7c-3.2,11.2,5.2,22.4,16.9,22.4h253.3c9,0,16.5-6.8,17.5-15.7 l13.4-126.7c1.1-10.4-7-19.4-17.5-19.4H671C663.2,299.1,656.3,304.3,654.1,311.8z",
          inner: [
            new IframeScene({id: 'Shipyard_Mseries', src: '/sites/default/files/interactive-products/Mseries', buttonText: 'Explore the equipment'}),
            new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=M-series&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
          ],
        }),
      ],
    }),
    new ProductScene({
      id: 'AHBCS',
      friendlyName: 'Automated High-Bay Container Storage',
      enteringVideoDuration: 3033,
      leavingVideoDuration: 833,
      trace: "M1380.6,392.2l74.8,216.3c1.9,5.4,6.9,8.9,12.6,8.9l237-1.9c9.7-0.1,16-10.3,11.7-19L1610.4,382 c-2.2-4.5-6.8-7.3-11.8-7.3h-205.5C1384,374.7,1377.7,383.6,1380.6,392.2z",
      hasBackToTopButton: false,
      inner: [
        new IframeScene({id: 'AHBCS_tech', src: '/sites/default/files/interactive-products/AHBCS', buttonText: 'Explore the equipment'}),
        new IframeScene({id: 'AHBCS_service', src: '/sites/default/files/interactive-products/AHBCSService', buttonText: 'Explore the related services'}),
        new ExternalLink({url: 'https://zone.konecranes.com/discover?search_api_fulltext=AHBCS&share_id=DreamTerminal', buttonText: 'Explore related ZONE content'}),
      ],
    }),
    new ProductScene({
      id: 'ControlTower',
      friendlyName: 'Control Tower (Software)',
      enteringVideoDuration: 3033,
      leavingVideoDuration: 833,
      trace: "M1068.1,207.7h73.1c5.6,0,10.1,4.5,10.1,10.1v54.8c0,5.7-4.6,10.2-10.3,10.1l-73.1-1.4 c-5.5-0.1-9.9-4.6-9.9-10.1v-53.5C1058,212.3,1062.5,207.7,1068.1,207.7z",
      hasBackToTopButton: false,
      inner: [
        new IframeScene({
          id: 'ControlTower_AutostoreTOS',
          src: '/sites/default/files/interactive-products/AutostoreTOS',
          buttonText: 'Autostore TOS',
          trace: 'M720.2,574.9l7.1,298.4c0.2,8.3,6.1,15.3,14.3,16.8l271.4,50.7c6,1.1,12.1-1,16.2-5.5 l109.4-121.3c2.8-3.1,4.4-7.1,4.5-11.3l5.7-216.7c0.2-9.3-6.8-17.1-16.1-17.9l-363-29.4c-3.6-0.3-7.2,0.5-10.4,2.4l-30.6,18.3 C723.4,562.7,720.1,568.6,720.2,574.9z'
        }),
        new IframeScene({
          id: 'ControlTower_CommTrac',
          src: '/sites/default/files/interactive-products/CommTrac',
          buttonText: 'CommTrac',
          trace: 'M1216.4,557.5l501.1,147.3c9.6,2.8,16.2,11.7,16.2,21.7v331c0,12.5-10.1,22.6-22.6,22.6 h-530.5c-3.8,0-7.6-1-11-2.8l-149.2-82.9c-7.2-4-11.6-11.5-11.6-19.8V649.3c0-9.3,5.7-17.6,14.3-21l178.6-70.1 C1206.4,556.3,1211.6,556,1216.4,557.5z'
        }),
        new IframeScene({
          id: 'ControlTower_AutostoreWMS',
          src: '/sites/default/files/interactive-products/AutostoreWMS',
          buttonText: 'Autostore WMS',
          trace: 'M327.7,591.2l25.7,302.2c0.7,8.8,8.3,15.5,17.1,15.1l286.3-12.1 c6-0.3,11.3-3.7,14-9l55-108.7c1.3-2.6,1.9-5.5,1.7-8.5l-12.2-199.4c-0.6-9-8.3-15.9-17.3-15.5l-354.8,18 C334,573.8,326.9,581.9,327.7,591.2z'
        }),
        new IframeScene({
          id: 'ControlTower_ROS',
          src: '/sites/default/files/interactive-products/ROS',
          buttonText: 'Remote Operating Station',
          trace: 'M532.3,475.6l207.8-15.1c0.3,0,0.7,0,1,0h251.1h185.7l336,16.1c0.3,0,0.6,0,0.8,0.1l343.1,38 c7.4,0.8,12.7,7.4,12,14.8l-26.3,261.9c-0.8,8.3-8.9,13.9-16.9,11.7l-390.9-104c-0.2-0.1-0.5-0.1-0.7-0.2l-366.2-75.9 c-0.4-0.1-0.7-0.1-1.1-0.2L752,583.5c-0.8-0.1-1.5-0.1-2.3-0.1l-215.8,9.6c-7.7,0.3-14.1-5.8-14.1-13.5v-90.3 C519.8,482,525.3,476.2,532.3,475.6z'
        }),
        new IframeScene({
          id: 'ControlTower_SelfServiceParts',
          src: '/sites/default/files/interactive-products/SelfServiceParts',
          buttonText: 'Self Service Parts',
          trace: 'M1567.1,134.6l-272.9,44.2c-5.7,0.9-9.5,6.4-8.3,12l26.5,125.9c1,4.7,5.2,8.1,10,8 l262.2-4.3c5.9-0.1,10.4-5.2,9.9-11l-15.7-165.8C1578.2,137.8,1572.8,133.6,1567.1,134.6z'
        }),
        new IframeScene({
          id: 'ControlTower_DataServices',
          src: '/sites/default/files/interactive-products/DataServices',
          buttonText: 'Data Services',
          trace: 'M1274.6,181.4l-215.1,22c-5.8,0.6-9.9,6-9,11.8l16.4,101.2c0.8,4.9,5.1,8.6,10,8.6h224.7 c6.5,0,11.3-5.9,10-12.3l-25.9-123.2C1284.5,184.3,1279.8,180.8,1274.6,181.4z'
        }),
        new IframeScene({
          id: 'ControlTower_MaintenanceServices',
          src: '/sites/default/files/interactive-products/MaintenanceServices',
          buttonText: 'Maintenance Services',
          trace: 'M844.3,214.9l196.8-10c4.3-0.2,8.1,2.9,8.8,7.1l16.7,103.2c0.8,5.1-3,9.7-8.2,9.9 l-204.7,5.2c-4.5,0.1-8.3-3.3-8.7-7.7l-8.8-98.3C835.9,219.4,839.5,215.2,844.3,214.9z'
        }),
        new IframeScene({
          id: 'ControlTower_OperationalSoftware',
          src: '/sites/default/files/interactive-products/OperationalSoftware',
          buttonText: 'Operational Software',
          trace: 'M826.6,214.9l-201.8-4.1c-5.5-0.1-9.6,5.1-8.2,10.5l27.3,103c1,3.7,4.3,6.2,8,6.2 H834c4.8,0,8.7-4.1,8.3-9l-7.6-98.9C834.4,218.3,830.9,215,826.6,214.9z'
        }),
        new IframeScene({
          id: 'ControlTower_SelfServiceInformation',
          src: '/sites/default/files/interactive-products/SelfServiceInformation',
          buttonText: 'Self Service Information',
          trace: 'M372.2,217.4l25.2,106.5c0.9,3.9,4.4,6.6,8.3,6.6h228.7c5.6,0,9.7-5.3,8.3-10.7 l-27.3-102.9c-1-3.7-4.3-6.3-8.1-6.4l-226.6-3.6C375.1,206.8,370.9,212,372.2,217.4z'
        }),
      ],
    }),
  ]
})

function addParentReferenceToInnerScenes(_parent) {
  _parent.inner.filter(s => s instanceof SceneBase).forEach(_scene => {
    _scene.parent = _parent
    addParentReferenceToInnerScenes(_scene)
  })
}
addParentReferenceToInnerScenes(topScene)

const sceneMap = {}
function fillMap(_parent) {
  sceneMap[_parent.id] = _parent
  _parent.inner.forEach(_scene => {
    fillMap(_scene)
  })
}
fillMap(topScene)


const logSceneTransitionInAnalytics = (fromSceneKey, toSceneKey) => {
  if (!window.dataLayer) {
    return
  }

  const fromScene = fromSceneKey ? sceneMap[fromSceneKey] : undefined
  const toScene = sceneMap[toSceneKey]

  window.dataLayer.push({
    event: 'DreamTerminalNavigation',
    from: fromScene?.id,
    fromSceneType: fromScene?.sceneType,
    to: toScene.id,
    toSceneType: toScene.sceneType,
  })
}


export {VideoScene, filesVersionNumber, IframeScene, ExternalLink, topScene, sceneMap, logSceneTransitionInAnalytics}
