import { useState, Fragment } from 'react'
import { IframeScene, VideoScene, ExternalLink, logSceneTransitionInAnalytics } from './scenes'

const SvgComponent = ({activeScene, indexExpanded, preloadedVideos, onPathClick, className, ...props}) => {
  const [hoveredPathId, setHoveredPathId] = useState(false)

  const loadingItems = activeScene.inner.filter(scene => (scene instanceof VideoScene)).filter(({loopVideo, enteringVideo}) => !preloadedVideos.includes(loopVideo) || !preloadedVideos.includes(enteringVideo)).length
  const _onPathClick = (e) => {
    setHoveredPathId(false)
    onPathClick(e)
  }

  return (
    <Fragment>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        x={0}
        y={0}
        viewBox="0 0 1920 1080"
        className={[className, hoveredPathId ? 'has-hovered-path' : undefined].join(' ')}
        {...props}
      >
        {activeScene.inner.filter(scene => (scene instanceof VideoScene)).map(({trace, id, loopVideo, enteringVideo}) => !!trace && preloadedVideos.includes(loopVideo) && preloadedVideos.includes(enteringVideo) && (
          <path
            key={id}
            onClick={onPathClick(id)}
            d={trace}
            className={["path-outline", hoveredPathId == id ? 'hovered' : undefined].join(' ')}
            onMouseEnter={() => setHoveredPathId(id)}
            onMouseLeave={() => setHoveredPathId(false)}
          />
        ))}
        {activeScene.inner.filter(scene => (scene instanceof ExternalLink)).map(({trace, id, url}) => !!trace && (
          <path
            key={id}
            onClick={() => {logSceneTransitionInAnalytics(activeScene.id, id); window.open(url, '_blank', 'noreferrer')}}
            d={trace}
            className={["path-outline", hoveredPathId == id ? 'hovered' : undefined].join(' ')}
            onMouseEnter={() => setHoveredPathId(id)}
            onMouseLeave={() => setHoveredPathId(false)}
          />
        ))}
        {activeScene.inner.filter(scene => (scene instanceof IframeScene)).map(({trace, id}) => !!trace && (
          <path
            key={id}
            onClick={onPathClick(id)}
            d={trace}
            className={["path-outline", hoveredPathId == id ? 'hovered' : undefined].join(' ')}
            onMouseEnter={() => setHoveredPathId(id)}
            onMouseLeave={() => setHoveredPathId(false)}
          />
        ))}
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        x={0}
        y={0}
        viewBox="0 0 1920 1080"
        className={[className, 'style-only', hoveredPathId ? 'has-hovered-path' : undefined].join(' ')}
        {...props}
      >
        <mask id="clickable-svg-mask">
          <rect fill="white" width="100%" height="100%"/>
          {activeScene.inner.map(({trace, id, loopVideo, enteringVideo}) => preloadedVideos.includes(loopVideo) && preloadedVideos.includes(enteringVideo) && (
            <path
              key={id}
              d={trace}
              className={["clickable-svg-mask-path", hoveredPathId == id ? 'hovered' : undefined].join(' ')}
            />
          ))}
        </mask>
        <rect mask="url(#clickable-svg-mask)" className="clickable-svg-background" width="100%" height="100%"/>
      </svg>
      <div className={"scene-index--wrapper"}>
        <div className={["scene-index", indexExpanded ? "scene-index--expanded" : undefined].join(' ')}>
          {activeScene.inner.filter(scene => (scene instanceof VideoScene)).map(({trace, friendlyName, id, loopVideo, enteringVideo}) => preloadedVideos.includes(loopVideo) && preloadedVideos.includes(enteringVideo) && (
            <a
              key={id}
              href="#"
              onClick={onPathClick(id)}
              className={["scene-index-link", hoveredPathId == id ? 'hovered' : undefined].join(' ')}
              onMouseEnter={() => (!!trace && setHoveredPathId(id))}
              onMouseLeave={() => (!!trace && setHoveredPathId(false))}
            >{friendlyName}</a>
          ))}
          {activeScene.inner.filter(scene => (scene instanceof IframeScene)).map(({id, buttonText, trace}) => (
            <a
              className="scene-index-link"
              key={id}
              href="#"
              onClick={onPathClick(id)}
              onMouseEnter={() => (!!trace && setHoveredPathId(id))}
              onMouseLeave={() => (!!trace && setHoveredPathId(false))}
            >{buttonText}</a>
          ))}
          {activeScene.inner.filter(scene => (scene instanceof ExternalLink)).map(({url, id, buttonText, trace}) => (
            <a
              key={id}
              href={url}
              target="_blank"
              className="scene-index-link"
              onClick={() => logSceneTransitionInAnalytics(activeScene.id, id)}
              onMouseEnter={() => (!!trace && setHoveredPathId(id))}
              onMouseLeave={() => (!!trace && setHoveredPathId(false))}
              rel="noreferrer"
            >{buttonText}</a>
          ))}
          {!!loadingItems && <div className="scene-index--loading-indicator">Loading {loadingItems} more...</div>}
        </div>
      </div>
    </Fragment>
  )
}
export default SvgComponent
