import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.scss';
import App from './App';

const standalone = document.body.classList.contains('standalone')

if (!standalone) {
  (function (Drupal, $) {
    Drupal.behaviors.dreamTerminal = {
      attach: function (context, settings) {
        const $dtTarget = $('#dream-terminal', context)
        if (!$dtTarget.length) {
          return
        }

        ReactDOM.render(
          <React.StrictMode>
            <App />
          </React.StrictMode>,
          $dtTarget.get(0)
        );
      }
    };

  })(window.Drupal, window.jQuery);
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('dream-terminal')
  );
}


